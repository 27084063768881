import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../containers/defaultLayout.js';
import AnimatedButton from '../components/animatedButton';

import ContactForm from '../containers/contactForm.js';

import {
    Container, Row, Col
} from 'reactstrap';

import rectangle from '../images/rectangle.png';
import image1 from '../images/happening/image1.jpg';
import image2 from '../images/happening/image2.jpg';

import balloon from '../images/animation/yellowBalloon.png';
import star from '../images/animation/star.png';
import kids from '../images/kids.jpg';
import doubleStars from '../images/animation/doubleStars.png';
import redBallonRight from '../images/animation/redBallonRight.png';

import blueBalloon from '../images/animation/blueBalloon.png';
import stars from '../images/animation/stars.png';

import yellowBalloon2 from '../images/animation/yellowBalloon2.png';
import greenBalloon from '../images/animation/greenBalloon.png';


export class about extends Component {



    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {
        const buttonText = "Pogledaj sva dešavanja";
        return (
            <div className="about">

                <div className="animatedBalloon">
                    <img src={balloon} className="balloon" />
                    <img src={star} className="star" />
                </div>
                <div className="animatedBalloonRight">
                        <img src={redBallonRight} className="balloonRight" />
                        <img src={doubleStars} className="starRight" />
                    </div>
                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  O nama</p>
                    </div>

                    <div className="content">
                        <h1>O nama</h1>

                        <p>Igraonica Wunderland predstavlja do sada neviđeni koncept igre i zabave u Banjaluci. Smještena u centru grada
                        na preko 2000m², ova igraonica nudi raznovrsne sadržaje prilagođene kako djeci i mladima tako i odraslima koji
                        su spremni da se upuste u svijet Virtual reality avantura. Prostor obuhvata odvojenu igraonicu za najmlađe (bebe i
                        djecu do 3 godine) čiji su sadržaji prilagođeni njihovim razvojnim i motoričkim sposobnostima, kao i igraonicu za
                        djecu većeg uzrasta koju čine trampoline, tobogani, lavirinti, penjalice, autići i slični sadržaji.</p>

                        <div className="imgWrapp">
                            <img src={kids} className="img-fluid" />
                        </div>

                        <p>Ono što Wunderland zaista čini "zemljom čuda" predstavlja program Virtual reality igara za sve uzraste i
                        prostrana Laser tag arena sa najsavremenijom opremom, koji zabavu podižu na sasvim novi nivo potpunog
                        doživljaja virtuelne stvarnosti. Za roditelje i druge posjetioce smo uredili kutak sa ugodnim ambijentom za odmor i
                        uživanje. Posjetite nas i uživajte u svijetu svakodnevne igre i zabave, ili nam prepustite organizaciju proslava
                        dječijih rođendana. Dođite i uvjerite se sami u jedinstven koncept zabave u našem gradu, te saznajte zašto su svi
                        drugari već u Wunderlandu!</p>
                    </div>
                </Container>

                <div className="happening">
                    
                <div className="animatedBalloon">
                    <img src={blueBalloon} className="balloon" />
                    <img src={stars} className="star" />
                </div>
                <div className="animatedBalloonRight">
                        <img src={yellowBalloon2 } className="balloonRight" />
                     
                    </div>
                    <Container>
                        <h1>Dešavanja</h1>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                        <Link to="/desavanja"><AnimatedButton buttonText={buttonText} /></Link>
                    </Container>
                </div>
                <ContactForm />

            </div>
        )
    }
}

export default DefaultLayout(about)
