import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import Home from './views/home.js';
import About from './views/about.js';
import AboutVr from './views/vr/aboutVR.js';
import AboutLaser from './views/laser/aboutLaser.js';

import Contact from './views/kids/contact.js'
import Birthday from './views/kids/birthday.js';
import BirthdayDetail from './views/kids/birthdayDetail.js';
import Gallery from './views/kids/gallery.js';
import Happening from './views/kids/happening.js';
import HappeningDetail from './views/kids/happeningDetail.js';
import DVirtual from './views/kids/9dVirtual.js';
import Offers from './views/kids/offers.js';
import Vr from './views/vr.js';

import Laser from './views/laser.js';
import GoogleMap from './googleMapScript.js';
import './App.css';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;
    this.state = {};
  }
  callback() {
    this.setState({ mapinit: true });
  }
  render() {
    return (
      <div>
         <GoogleMap API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />
        <Router>
          <Switch>
            <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state}></Home>)} />
            <Route exact path="/o-nama" render={(...renderProps) => (<About {...renderProps} {...this.state} />)} />
            <Route exact path="/o-nama-vr" render={(...renderProps) => (<AboutVr {...renderProps} {...this.state} />)} />
            <Route exact path="/o-nama-laser" render={(...renderProps) => (<AboutLaser {...renderProps} {...this.state} />)} />

            <Route exact path="/kontakt" render={(...renderProps) => (<Contact {...renderProps} {...this.state}/>)} />
            <Route exact path="/rodjendani" render={(...renderProps) => (<Birthday {...renderProps} {...this.state}/>)} />
            <Route exact path="/rodjendani/rezervacija/:id" render={(...renderProps) => (<BirthdayDetail {...renderProps} {...this.state}/>)} />
            <Route exact path="/galerija" render={(...renderProps) => (<Gallery {...renderProps} {...this.state}/>)} />
            <Route exact path="/desavanja" render={(...renderProps) => (<Happening {...renderProps} {...this.state}/>)} />
            <Route exact path="/desavanja/:id" render={(...renderProps) => (<HappeningDetail {...renderProps} {...this.state}/>)} />
            <Route exact path="/ponude/:id/:id2" render={(...renderProps) => (<DVirtual {...renderProps} {...this.state}/>)} />
            <Route exact path="/ponude" render={(...renderProps) => (<Offers {...renderProps} {...this.state}/>)} />
            <Route exact path="/vr" render={(...renderProps) => (<Vr {...renderProps} {...this.state}/>)} />
            <Route exact path="/laser" render={(...renderProps) => (<Laser {...renderProps} {...this.state}/>)} />
          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;
