import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';
import Gallery from '../../containers/kids/gallery.js'
import {
    Container
} from 'reactstrap';


import yellowBalloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';
import blueBalloon from '../../images/animation/blueBalloon.png';
import redBallonRight from '../../images/animation/redBallonRight.png';
import doubleStars from '../../images/animation/doubleStars.png'

export class gallery extends Component {
    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {
        return (
            <div className="gallery">

;


<div className="animatedBalloon">
<img src={yellowBalloon} className="balloon" />
<img src={star} className="star" />
<img src={blueBalloon} className="balloon blueBalloon"/>
</div>

<div className="animatedBalloonRight">
    <img src={redBallonRight} className="balloonRight" />
    <img src={doubleStars} className="starRight" />
</div>

                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  Galerija</p>
                    </div>

                    <h1>Galerija</h1>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>

                    <Gallery />
                </Container>
            </div>
        )
    }
}

export default DefaultLayout(gallery)
