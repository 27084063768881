import React, { Component } from 'react'
import galleryPicture1 from '../../images/galleryPicture1.png';
import galleryPicture2 from '../../images/galleryPicture2.png';
import galleryPicture3 from '../../images/galleryPicture3.png';
import galleryPicture4 from '../../images/galleryPicture4.png';
import galleryPicture5 from '../../images/galleryPicture5.png';
import galleryPicture6 from '../../images/galleryPicture6.png';

import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';

const pictures = [
    {
        src: galleryPicture1,
        altText: 'Picture1',
        caption: 'Picture1'
    },
    {
        src: galleryPicture2,
        altText: 'Picture2',
        caption: 'Picture2'
    },
    {
        src: galleryPicture3,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: galleryPicture4,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: galleryPicture5,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: galleryPicture6,
        altText: 'Picture3',
        caption: 'Picture3'
    },
];


class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,

        };

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === pictures.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? pictures.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {

        const activeIndex = this.state.activeIndex;
        const slides = pictures.map((item, idx) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <img src={item.src} className="img-fluid" />

                </CarouselItem>
            )
        });

        return (
            <>
                <div className="galleryPictures">
                    <Row>
                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })} >
                                <img src={galleryPicture1} className="img-fluid" />
                            </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })} >
                                <img src={galleryPicture2} className="img-fluid" />
                            </div>
                        </Col>

                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 2 })}>
                                <img src={galleryPicture3} className="img-fluid" />
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 3 })}>
                                <img src={galleryPicture4} className="img-fluid" />
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 4 })}>
                                <img src={galleryPicture5} className="img-fluid" />
                            </div>
                        </Col>
                        <Col xl="4" lg="4" md="6" xs="6">
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 5 })}>
                                <img src={galleryPicture6} className="img-fluid" />
                            </div>
                        </Col>
                    </Row>
                </div>
                {this.state.opened ?
                    <div className="lightbox">
                        <Container>
                            <Row>
                                <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                <Carousel className="bg"
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    <CarouselIndicators items={pictures} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides}
                                    <i className="fa fa-angle-left" onClick={this.previous}></i>
                                    <i className="fa fa-angle-right" onClick={this.next}></i>
                                </Carousel>
                            </Row>
                        </Container>
                    </div> : null
                }
            </>


        )
    }
}

export default Gallery
