import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import AnimatedButton from '../../components/animatedButton';
import Slider from "react-slick";
import ponudaPicture1 from '../../images/ponudaPicture1.png';
import ponudaPicture2 from '../../images/ponudaPicture2.png';
import ponudaPicture3 from '../../images/ponudaPicture3.png';
import ponudaPicture4 from '../../images/ponudaPicture4.png';
import ponudaPicture5 from '../../images/ponudaPicture5.png';
import ponudaPicture6 from '../../images/ponudaPicture6.png';


import LinesLeft from '../../images/vr/linesLeft.png';
import RinesRight from '../../images/vr/linesRight.png';



import {
    Container, Row, Col
} from 'reactstrap';

export class activityOffer extends Component {
    render() {
        const buttonText = "Sve";
        const buttonText2 = "Baby Corner";
        const buttonText3 = "Laser Tag";
        const buttonText4 = "Igraonica 4+";
        const buttonText5 = "9D Virtual Tenk";
        const buttonText6 = "Kids Gaming Corner";
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true

        };
        return (
            <div className="activityOffer">

                <div className="lines">
                    <img src={LinesLeft} className="linesLeft" />
                    <img src={RinesRight} className="rinesRight" />
                </div>
                <Container>
                    <Row>
                        <Col xl="12">
                            <h1>Ponuda aktivnosti</h1>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam.</p>
                        </Col>

                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Slider {...settings}>
                            <div className="wrapper">
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture1} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Penjalice</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture2} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Tobogani</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture3} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Bazen sa lopticama</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture4} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>9D Virtual Tenk</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture4} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Laser Tag</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture1} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Trampoline</p>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                            <div className="wrapper">
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture1} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Penjalice</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture2} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Penjalice</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture3} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Penjalice</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture4} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Penjalice</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture4} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Penjalice</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <div className="box">
                                        <img src={ponudaPicture1} className="img-fluid"></img>
                                        <div className="textBox">
                                            <p>Penjalice</p>
                                        </div>
                                    </div>
                                </Col>
                            </div>
                        </Slider>
                    </Row>
                </Container>

            </div >
        )
    }
}

export default activityOffer
