import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';
import AnimatedButton from '../../components/animatedButton';
import HappeningComponent from '../../containers/kids/happeningComponent.js';

import ContactForm from '../../containers/contactForm.js';

import {
    Container, Row, Col
} from 'reactstrap';

import rectangle from '../../images/rectangle.png';
import image1 from '../../images/happening/image1.jpg';
import image2 from '../../images/happening/image2.jpg';

import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';

import doubleStars from '../../images/animation/doubleStars.png';
import redBallonRight from '../../images/animation/redBallonRight.png';

import blueBalloon from '../../images/animation/blueBalloon.png';
import stars from '../../images/animation/stars.png';

import yellowBalloon2 from '../../images/animation/yellowBalloon2.png';
import greenBalloon from '../../images/animation/greenBalloon.png';
import LinesLeft from '../../images/vr/linesLeft.png';
import RinesRight from '../../images/vr/linesRight.png';


import laser from '../../images/laser.png';

import linesLeft from '../../images/vr/linesLeft.png';
import linesRight from '../../images/vr/linesRight.png';



export class about extends Component {



    componentWillMount() {
        window.scroll(0, 0);
    }
    render() {
        const buttonText = "Pogledaj sva dešavanja";
        return (
            <div className="about">
                <div className="vrAnim">
                    <img src={linesLeft} className="leftVr"/>
                    <img src={linesRight} className="rightVr"/>
                </div>
                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  O nama</p>
                    </div>

                    <div className="content">
                        <h1>O nama</h1>

                        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                            sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                             Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                             Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                             laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                          incididunt ut labore et dolore magna aliqua.</p>

                        <div className="imgWrapp">
                            <img src={laser} className="img-fluid" />
                        </div>

                        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                         nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit
                         in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                          occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
                           id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                             Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                              laborum.!</p>
                    </div>
                </Container>

                <div className="happening">

                 
                    <Container>
                        <h1>Dešavanja</h1>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                        <Link to="/desavanja"><AnimatedButton buttonText={buttonText} /></Link>
                    </Container>
                </div>
                <ContactForm />

            </div>
        )
    }
}

export default DefaultLayout(about)
