import React from 'react';
import {Link} from 'react-router-dom';
import Navigacija from './navbar';
import Footer from './footer';
import Map from '../containers/map.js';

export const DefaultLayout = (Wrapped) => (props) => {
        return(
            <div>
                <Navigacija {...props}
                    vr = {props[0].location.pathname == "/vr" ? false : true}
                    laser = {props[0].location.pathname == "/laser" ? false : true}
                />
                <Wrapped {...props} />
                <Map {...props}/>
                <Footer {...props}/>
            </div>
        )
}

export default DefaultLayout;