import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout.js';
import Carousel from '../containers/kids/carousel.js';
import Welcome from '../containers/laser/welcome.js';
import HomeOffer from '../containers/laser/homeOffer.js';
import ActivityOffer from '../containers/laser/activityOffer.js';
import Gallery from '../containers/laser/gallery.js';
import {Link} from 'react-router-dom';
import AnimatedButton from '../components/animatedButton.js';
import HappeningComponent from '../containers/kids/happeningComponent.js';
import ContactForm from '../containers/contactForm.js';

import image1 from '../images/happening/image1.jpg';
import image2 from '../images/happening/image2.jpg';
import {
    Container, Row
} from 'reactstrap';

import blueGun from '../images/laser/blueGun.png';
import happeningLaser from '../images/laser/happeningLaser.png';


export class laser extends Component {
    render() {
        const buttonText = "Pogledaj kompletnu galeriju"; 
        const buttonText2 = "Pogledaj sva dešavanja";
        return (
            <div>
               <Carousel/>
                <Welcome/>
                <HomeOffer/>
                <ActivityOffer/>

                <div className="homeGallery vr">
                    <Container>
                        <Row>
                            <div className="title">
                                <h1>Galerija</h1>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                            </div>
                        </Row>
                        <Gallery />
                        <div className="homeButtonWrapper">
                            <Link to="/galerija"><AnimatedButton buttonText={buttonText} /></Link>
                        </div>
                    </Container>
                </div>


                <div className="happening">
               
                <div className="vrAnim">
                    <img src={blueGun} className="gun"/>
                    <img src={happeningLaser} className="laser"/>
                </div>
                    <Container>
                        <h1>Dešavanja</h1>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                        <Link to="/desavanja"><AnimatedButton buttonText={buttonText2} /></Link>
                    </Container>
                </div>
                <ContactForm/>
            </div>
        )
    }
}

export default DefaultLayout(laser)
