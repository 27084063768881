import React, { Component } from 'react'
import AnimtedButton from '../../components/animatedButton.js';
import WelcomeImage from '../../images/welcomeImage.png';
import {
    Container,
    Row,
    Col
} from 'reactstrap';
import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';

import redBallonRight from '../../images/animation/redBallonRight.png';
import starYellow from '../../images/animation/starYellow.png';
import {Link} from 'react-router-dom';

class Welcome extends Component {
    render() {
        const buttonText = "Detaljnije"
        return (
            <div className="welcomeSection">

                <Container>
                    <div className="animatedBalloon">
                        <img src={balloon} className="balloon" />
                        <img src={star} className="star" />
                    </div>

                    <div className="animatedBalloonRight">
                        <img src={redBallonRight} className="balloonRight" />
                        <img src={starYellow} className="starRight" />
                    </div>

                    <Row>

                        <Col xl="6" lg="6" md="6" xs="12">
                            <h1>Dobrodošli u Wunderland</h1>
                            <p>Wunderland predstavlja do sada neviđeni
                            koncept igre i zabave u Banjaluci. Smještena u centru
                            grada na preko 2000m², ova igraonica nudi raznovrsne
                            sadržaje prilagođene kako djeci i mladima tako i
                            odraslima koji su spremni da se upuste u svijet Virtual
                        reality avantura.</p>
                            
                            <Link to='/o-nama'><AnimtedButton buttonText={buttonText} /></Link>
                        </Col>

                        <Col xl="5" lg="5" md="6" xs="12" className="offset-xl-1 offset-lg-1">
                            <img src={WelcomeImage} className="img-fluid img"></img>
                        </Col>

                    </Row>
                </Container>
            </div>
        )
    }
}

export default Welcome;
