import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';
import AnimatedButton from '../../components/animatedButton.js';
import {
    Container, Row, Col
} from 'reactstrap';
import image from '../../images/image.png';
import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';

import doubleStars from '../../images/animation/doubleStars.png';
export class birthday extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: []
        }
    }
    componentWillMount() {
        window.scroll(0, 0);
    }

    componentDidMount() {
        fetch('https://euroexpress.tips:4002/api/v1/pub/offers_get', {
            headers: {
                Authorization: 'Bearer IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo',
                Accept: 'application/json, text/plain, */*'
            }
        }).then((res) => res.json()).then((result) => {
            console.log(result);
            this.setState({
                items: result
            })
        })
    }

    render() {
        const buttonText = "Rezerviši";
        return (
            <div className="birthday">
                <div className="animatedBalloon">
                    <img src={balloon} className="balloon" />
                    <img src={star} className="star" />
                </div>

                <div className="animatedBalloonRight">

                    <img src={doubleStars} className="starRight" />
                </div>
                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  Rođendani</p>
                    </div>

                    <div className="content">
                        <h1>Rođendani</h1>
                        <p>Igraonica „Wunderland“ organizuje proslave dječijih rođendana za sve uzraste. Dok djeca provode nezaboravne
                        rođendanske trenutke u igraonici, za roditelje smo pripremili adekvatan prostor gdje uz konzumaciju hrane i pića
                        mogu pratiti proslavu i neometano uživati.</p>
                    </div>

                    <Row>
                        {
                            this.state.items.map((item, idx) => {
                                return (
                                    <Col xl="4" lg="4" md="4" xs="12">
                                        <div className="box">
                                            <img src={`https://euroexpress.tips:4002/images/image_get/${item.image && item.image.url}`} className="img-fluid" />

                                            <div className="content">
                                                <h2>{item.title}</h2>
                                                <Link to={`/rodjendani/rezervacija/${item.id}`}><AnimatedButton buttonText={buttonText} /></Link>
                                            </div>
                                        </div>
                                    </Col>
                                )

                            })
                        }
                    </Row>

                </Container>
            </div>
        )
    }
}

export default DefaultLayout(birthday)
