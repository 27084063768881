import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/defaultLayout.js';
import HappeningComponent from '../../containers/kids/happeningComponent.js';
import image1 from '../../images/happening/image1.jpg';
import image2 from '../../images/happening/image2.jpg';


import LinesLeft from '../../images/vr/linesLeft.png';
import RinesRight from '../../images/vr/linesRight.png';

import {
    Container,
    Row,
    Col
} from 'reactstrap';
import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';
import blueBalloon from '../../images/animation/blueBalloon.png';
import doubleStars from '../../images/animation/doubleStars.png';
import redBallonRight from '../../images/animation/redBallonRight.png';
import axios from 'axios';


export class happening extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }

    componentWillMount() {
        window.scroll(0, 0);
    }

    componentDidMount() {

        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get(`https://euroexpress.tips:4002/api/v1/pub/article_all`)
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })
    }
    render() {
        console.log(this.state.data);
        return (
            <div className="happeningPage">

                <div className="animatedBalloon">
                    <img src={balloon} className="balloon" />
                    <img src={star} className="star" />
                    <img src={blueBalloon} className="balloon blueBalloon" />

                </div>
                <div className="animatedBalloonRight">
                    <img src={redBallonRight} className="balloonRight" />
                    <img src={doubleStars} className="starRight" />
                </div>

                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  Dešavanja</p>
                    </div>
                    <h1>Dešavanja</h1>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                    <Row>
                        {this.state.data.map((items, idx) => {
                            const ENDPOINT = "https://euroexpress.tips:4002";
                            const IMAGES = ENDPOINT + "/images/image_get/";
                            return (
                                <Col xl="6" lg="6" md="6" xs="12" key={items.id}>
                                    <div className="boxHappening">
                                        <div className="contentHappening">
                                            <Link to={`/desavanja/${items.id}`}>
                                                <h5>{items.title}</h5></Link>
                                            <h6>{items.timestamp}</h6>
                                            <p>{items.text}</p>
                                        </div>
                                        <div className="image">
                                            <Link to={`/desavanja/${items.id}`}>
                                                <img src={IMAGES + items.banner.url} className="img-fluid" />
                                            </Link>
                                        </div>
                                    </div>
                                </Col>

                            )
                        })}
                    </Row>

                </Container>
            </div>
        )
    }
}

export default DefaultLayout(happening)
