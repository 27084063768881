import React, { Component } from 'react'
import DefaultLayout from '../containers/defaultLayout.js';
import Carousel from '../containers/kids/carousel.js';
import Welcome from '../containers/vr/welcome.js';
import HomeOffer from '../containers/vr/homeOffer.js';
import ActivityOffer from '../containers/vr/activityOffer.js';
import Gallery from '../containers/vr/gallery.js';
import HappeningComponent from '../containers/kids/happeningComponent.js';
import ContactFrom from '../containers/contactForm.js';
import AnimatedButton from '../components/animatedButton.js';
import { Link } from 'react-router-dom';

import {
    Container, Row
} from 'reactstrap';


import image1 from '../images/happening/image1.jpg';
import image2 from '../images/happening/image2.jpg';


import LinesLeft from '../images/vr/linesLeft.png';
import RinesRight from '../images/vr/linesRight.png';

export class vr extends Component {
    render() {

        const buttonText = "Pogledaj kompletnu galeriju";
        const buttonText2 = "Pogledaj sva dešavanja";

        return (
            <div>
                <Carousel />
                <Welcome />
                <HomeOffer />
                <ActivityOffer />

                <div className="homeGallery vr">
                    <Container>
                        <Row>
                            <div className="title">
                                <h1>Galerija</h1>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                            </div>
                        </Row>
                        <Gallery />
                        <div className="homeButtonWrapper">
                            <Link to="/galerija"><AnimatedButton buttonText={buttonText} /></Link>
                        </div>
                    </Container>
                </div>

                <div className="happening">
                    <div className="lines">
                        <img src={LinesLeft} className="linesLeft" />
                        <img src={RinesRight} className="rinesRight" />
                    </div>
                    <Container>
                        <h1>Dešavanja</h1>
                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore.</p>
                        <Link to="/desavanja"><AnimatedButton buttonText={buttonText2} /></Link>
                    </Container>
                </div>
                <ContactFrom />
            </div>
        )
    }
}

export default DefaultLayout(vr)
