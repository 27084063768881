import React, { Component } from 'react'
import AnimatedButton from '../../components/animatedButton';
import DefaultLayout from '../../containers/defaultLayout.js';
import { Link } from 'react-router-dom';
import {
    Container, Row, Col
} from 'reactstrap';
import dvirtual from '../../images/offers/dvirtual.png';
import baby from '../../images/offers/baby.png';
import igraonica from '../../images/offers/igraonica.png';
import kids from '../../images/offers/kids.png';
import balloon from '../../images/animation/yellowBalloon.png';
import star from '../../images/animation/star.png';
import blueBalloon from '../../images/animation/blueBalloon.png';
import doubleStars from '../../images/animation/doubleStars.png';
import redBallonRight from '../../images/animation/redBallonRight.png';

import balonStar from '../../images/animation/balonStar.png';
import axios from 'axios';


export class offers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                categories: [],
            },
            items: []
        }
    }

    componentWillMount() {
        window.scroll(0, 0);
    }
    componentDidMount() {

        const API_KEY = "IeejPjvxjZyJtL3KC3kSQY0wmMTI3VqHPdCbiqkQeVw5VzVyX96FYGiAXm1RLypo";

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            Authorization: "Bearer " + API_KEY
        }

        axios.get(`https://euroexpress.tips:4002/api/v1/pub/get_menu`)
            .then(res => {
                const data = res.data;
                this.setState({ data });
            })
    }

    render() {
        console.log(this.state.data)
        const buttonText = "Detaljnije";
        return (
            <div className="offers">
                <div className="animatedBalloon">
                    <img src={balloon} className="balloon" />
                    <img src={star} className="star" />
                    <img src={blueBalloon} className="balloon blueBalloon" />
                    <img src={doubleStars} className="star doubleStars" />
                </div>


                <div className="animatedBalloonRight">
                    <img src={redBallonRight} className="balloonRight" />
                    <img src={doubleStars} className="starRight" />
                    <img src={balonStar} className="balloonRight balonStar" />
                </div>

                <Container>
                    <div className="breadcrumbs">
                        <p><Link to="/">Početna </Link>  Ponude</p>
                    </div>
                    <div className="contentOffer">
                        <h1>Ponude</h1>


                        {
                            this.state.data.categories.map((item, idx) => {
                                const ENDPOINT = "https://euroexpress.tips:4002" + "/images/image_get/";
                                return (
                                    <>
                                        <h3>{item.title}</h3>
                                        {item.items.map((item2, idx2) => {
                                            return (
                                                <Row>
                                                    <Col xl="4" lg="4" md="4" xs="12">

                                                        <div className="box">
                                                            <img src={ENDPOINT + item2.image.url} className="img-fluid" />
                                                            <div className="title">
                                                                <h6>{item2.title}</h6>
                                                                <p>8 minuta</p>
                                                                <Link to={`/ponude/${item.id}/${idx2}`}><AnimatedButton buttonText={buttonText} /> </Link>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            )
                                          
                                        })}

                                    </>

                                )
                            })
                        }





                    </div>
                </Container>

            </div>
        )
    }
}

export default DefaultLayout(offers)
